// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mapa-del-sitio-jsx": () => import("./../../../src/pages/mapa-del-sitio.jsx" /* webpackChunkName: "component---src-pages-mapa-del-sitio-jsx" */),
  "component---src-pages-nuestras-marcas-jsx": () => import("./../../../src/pages/nuestras-marcas.jsx" /* webpackChunkName: "component---src-pages-nuestras-marcas-jsx" */),
  "component---src-pages-terminos-y-condiciones-jsx": () => import("./../../../src/pages/terminos-y-condiciones.jsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-jsx" */)
}

